import 'flatpickr/dist/flatpickr.min.css';
import 'select2/dist/css/select2.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'tippy.js/dist/tippy.css';

import 'tailwindcss/tailwind.css';
import '../css/tailwind-migrate.css';

import '../css/common/fonts.css';
import '../css/project.css';

import 'jquery';

//Datatables
import 'datatables.net';

// Flatpickr - a visual date and hour picker
import Flatpickr from 'flatpickr';
import { French } from 'flatpickr/dist/l10n/fr.js';
Flatpickr.localize(French);

// Select2
import 'select2/dist/js/select2.full.js';
$.fn.select2.amd.define('select2/i18n/fr',[],require('select2/src/js/select2/i18n/fr'));

import './project.js';

// FosJsRouting bundle
const routes = require('../../public/js/fos_js_routes.json');
import Routing from '../../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);

import IntlMessageFormat from 'intl-messageformat';
global.IntlMessageFormat = window.IntlMessageFormat = IntlMessageFormat;
import Translator from 'bazinga-translator';
global.Translator = window.Translator = Translator;
