import tippy from 'tippy.js';
import MicroModal from 'micromodal';

const themeToggleDarkIcon = document.getElementById('theme-toggle-dark-icon');
const themeToggleLightIcon = document.getElementById('theme-toggle-light-icon');

if(themeToggleDarkIcon !== null && themeToggleLightIcon !== null) {
    if (localStorage.theme === 'dark' || (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)) {
        themeToggleLightIcon.classList.remove('hidden');
    } else {
        themeToggleDarkIcon.classList.remove('hidden');
    }
}

const themeSwitchButton = document.querySelector('#theme-toggle');

if (themeSwitchButton !== null) {
    themeSwitchButton.addEventListener('click', () => {
        themeToggleDarkIcon.classList.toggle('hidden');
        themeToggleLightIcon.classList.toggle('hidden');

        if (localStorage.getItem('theme')) {
            if (localStorage.getItem('theme') === 'light') {
                document.documentElement.classList.add('dark');
                localStorage.setItem('theme', 'dark');
            } else {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('theme', 'light');
            }
        } else {
            if (document.documentElement.classList.contains('dark')) {
                document.documentElement.classList.remove('dark');
                localStorage.setItem('theme', 'light');
            } else {
                document.documentElement.classList.add('dark');
                localStorage.setItem('theme', 'dark');
            }
        }
    });
}


Element.prototype.hasClass = function(elemClass) {
    return new RegExp(' ' + elemClass + ' ').test(' ' + this.className + ' ');
};
Element.prototype.removeClass = function(elemClass) {
    var newClass = ' ' + this.className.replace( /[\t\r\n]/g, ' ') + ' ';
    if (this.hasClass(elemClass)) {
        while (newClass.indexOf(' ' + elemClass + ' ') >= 0 ) {
            newClass = newClass.replace(' ' + elemClass + ' ', ' ');
        }
        this.className = newClass.replace(/^\s+|\s+$/g, '');
    }
};
Element.prototype.addClass = function(elemClass) {
    if (!this.hasClass(elemClass)) {
        if(this.className !=''){
            this.className += ' ' + elemClass;
        }
        else{
            this.className = elemClass;
        }
    }
};

$( document ).ajaxError(function( event, request ) {
    if(request.status === 503) {
        // When maintenance mode is active, redirect to current page to trigger maintenance page display
        return window.location.reload();
    }
});

(function($) {
    // Round progress bar plugin
    $.fn.easyProgress = function(options) {

        var defaults = {
            progress: 0,
            lineWidth : '10',
            lineCap : 'round',
            strokeStyle : 'black',
            endLineDecoration : false,
            elWidth : 40,
            elHeight : 40
        };

        var optionsExtended = $.extend(defaults, options);
        return this.each(function(index, element) {
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');

            var elWidth = optionsExtended.elWidth;
            var elHeight = optionsExtended.elHeight;

            var radius = Math.ceil((elHeight / 2) - (optionsExtended.lineWidth/2));
            var externalLineWidth = Math.floor(optionsExtended.lineWidth/2);
            var startAngle = -0.5 * Math.PI;
            var endAngle = ((0.5 * optionsExtended.progress / 25) - 0.5) * Math.PI;
            var counterClockwise = false;

            if(optionsExtended.endLineDecoration &&  optionsExtended.endLineDecoration.style == 'circle'){
                var circleDecorationAngle = endAngle;
                var circleDecorationRadius = Math.floor(radius*20/100);
                var canvasWidth = elWidth + circleDecorationRadius*2 + externalLineWidth*2;
                var canvasHeight = elHeight + circleDecorationRadius*2 + externalLineWidth*2;
                canvas.style.top = -(circleDecorationRadius + externalLineWidth)+'px';
                canvas.style.left = -(circleDecorationRadius + externalLineWidth)+'px';
                endAngle = endAngle - (2*Math.asin(circleDecorationRadius/(2*radius)));
                if(endAngle < -0.5*Math.PI){
                    endAngle = -0.5*Math.PI;
                }
            } else {
                canvasWidth = elWidth;
                canvasHeight = elHeight;
            }
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            var x = canvasWidth / 2;
            var y = canvasHeight / 2;

            context.strokeStyle = optionsExtended.strokeStyle;
            context.lineCap = optionsExtended.lineCap;
            context.lineWidth = optionsExtended.lineWidth;

            context.beginPath();
            context.arc(x, y, radius, startAngle, endAngle, counterClockwise);
            context.stroke();
            context.closePath();

            if(optionsExtended.endLineDecoration &&  optionsExtended.endLineDecoration.style == 'circle'){
                var xCircle = x + radius * Math.cos(circleDecorationAngle);
                var yCircle = y + radius * Math.sin(circleDecorationAngle);
                context.beginPath();
                context.arc(xCircle, yCircle, circleDecorationRadius, 0, Math.PI*2, false);
                if(optionsExtended.endLineDecoration.fill){
                    context.fillStyle   = optionsExtended.endLineDecoration.fill;
                    context.fill();
                }
                context.stroke();
                context.closePath();

            }
            element.appendChild(canvas);
        });
    };

    // Collapse plugin
    $(function() {
        var options = {
            collapseButtonClass : '' +
                'collapse-button',
            collapsePanelClass : 'collapse-panel',
        };

        $('.'+options.collapseButtonClass).click(function(){
            if($(this).hasClass('active')){
                $('.'+options.collapsePanelClass).hide();
                $('.'+options.collapseButtonClass+'.active').removeClass('active');
            } else {
                $('.'+options.collapseButtonClass+'.active').removeClass('active');
                $(this).addClass('active');
                $('.'+options.collapsePanelClass).hide();
                $($(this).data('target')).show();
            }
        });
    });

    // Exam popup
    $(function() {
        /* Mobile menu */
        $('#show-menu').on( 'click', function() {
            $('#nav-mobile').show();
            $('body').addClass('noscroll');
        });
        $( '#hide-menu').on( 'click', function() {
            $('#nav-mobile').hide();
            $('body').removeClass('noscroll');
        });
    });

    $(function () {
        $('.copy-btn').on('click', function () {
            const target = $(this).data('copyTarget');
            $(target).focus();
            $(target).select();
            try {
                const successful = document.execCommand('copy');
                const msg = successful ? 'Copié !' : 'Erreur :(';
                $(this).text(msg);
            } catch (err) {
                console.log('Oops, unable to copy');
            }

        });

    });


    // Alert
    $(function () {
        $('.alert > .close').on( 'click', function() {
            $('.alert').fadeOut(250);
        });
    });

    // Select2
    $(function () {
        $('select:not(.classic)').each(function() {
            if (jQuery('option', this).length < 5) {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'text-sm'
                });
            } else if ($(this).parent().parent().hasClass('no-search')) {
                $(this).select2({
                    minimumResultsForSearch: -1,
                    dropdownCssClass: 'text-sm'
                });
            } else {
                $(this).select2({ dropdownCssClass: 'text-sm' });
            }
        });
    });

    // Datepicker
    $(function () {
        $('.datepicker').flatpickr({
            locale: 'fr',
            altFormat: 'd/m/Y',
            altInput: true,
            allowInput: true,
            altInputClass: '',
            dateFormat: 'Y-m-d H:i:S',
            minDate: '2015-01-01 00:00:00',
            time_24hr:true,
            defaultHour: 0,
            onOpen: function(selectedDates, dateStr, instance) {
                if($(instance.altInput).prop('required')){
                    $(instance.altInput).prop('readonly', true);
                }
            },
            onClose: function(selectedDates, dateStr, instance) {
                if($(instance.altInput).prop('required')) {
                    $(instance.altInput).prop('readonly', false);
                    $(instance.altInput).blur();
                }
            }
        });
    });

    // DataTable
    $(function () {
        // if a column with class sort_desc is found, sort it by desc
        var order;
        if ($('.sort-desc').index() == -1) {
            order = [[ 0, 'desc' ]];
        } else {
            order = [[$('.sort-desc').index(), 'desc']];
        }

        if ($('.sort-asc').index() == -1) {
            order = [[ 0, 'asc' ]];
        } else {
            order = [[$('.sort-asc').index(), 'asc']];
        }

        $('.datatable').each(function() {

            var paging = true;
            var searching = true;
            var info = true;
            var pagingType = $(window).width() < 768 ? 'simple' : 'full_numbers';
            // If datatable has 'waiting-table' class, disabled pagination & search
            if ($(this).hasClass('waiting-table')) {
                paging = false;
                searching = false;
                info = true;
                pagingType = 'full_numbers';
            } else if ($(this).hasClass('mini-table')) {
                paging = true;
                searching = false;
                info = true;
                pagingType = 'simple';
            }


            $(this).dataTable({
                paging: paging,
                pageLength: 50,
                searching: searching,
                info: info,
                order: order,
                pagingType: pagingType,
                sDom: 'flrtip',
                bLengthChange: false,
                aoColumnDefs: [
                    { 'bSortable' : false, 'aTargets' : ['sort-no'] },
                ],
                language: {
                    decimal: '',
                    emptyTable: '---',
                    info: '_START_ - _END_ / _TOTAL_',
                    infoEmpty: '',
                    infoFiltered: '(Total Max: _MAX_)',
                    infoPostFix: '',
                    thousands: ',',
                    lengthMenu: 'Afficher _MENU_ leçons',
                    loadingRecords: 'Chargement...',
                    processing: 'Traitement des données...',
                    search: '<i class="fa-solid fa-magnifying-glass fa-icon-input"></i><span class="hidden">Rechercher : </span>',
                    zeroRecords: 'Aucun résultat pour cette recherche.',
                    paginate: {
                        previous: '&lt;',
                        next: '&gt;',
                        first: '&lt;&lt;',
                        last: '&gt;&gt;'
                    },
                    aria: {
                        sortAscending: ': activate to sort column ascending',
                        sortDescending: ': activate to sort column descending'
                    }
                },
                fnInitComplete: function () {
                    $('.waves-table-container').removeClass('hidden');
                    $('.waves-table-loader').hide();
                    var datatable = this;
                    if (this.attr('id') == 'learner-table') {
                        $('<div>', {
                            id: 'table-filters-container',
                        }).appendTo('.dataTables_filter');
                        $('<label class="table-filters"> \
                            <select id="table-filters-select" class="form-control-lg"> \
                            <option value="nofilter">Aucun filtre</option> \
                            <option value="certified">Tous les certifiés</option> \
                            <option value="gold">Certifiés Or</option> \
                            <option value="loading">Correction en cours</option> \
                            <option value="active">Actifs</option> \
                            <option value="inactive">Inactifs</option> \
                            <option value="active-contract">Sessions en cours</option> \
                            </select> \
                            </label>')
                            .appendTo( $('#table-filters-container') );
                        $( '#table-filters-select' ).change(function() {
                            const $tableFilters = $('#table-filters-select');
                            if ($tableFilters.val() === 'nofilter') {
                                datatable.api().columns().search('').draw();
                            } else if ($tableFilters.val() === 'certified') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(6).search('Certificat').draw();
                            } else if ($tableFilters.val() === 'gold') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(6).search('Certificat or').draw();
                            } else if ($tableFilters.val() === 'loading') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(6).search('TP en correction').draw();
                            } else if ($tableFilters.val() === 'active') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(7).search('jour').draw();
                            } else if ($tableFilters.val() === 'inactive') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(7).search('-').draw();
                            } else if ($tableFilters.val() === 'active-contract') {
                                datatable.api().columns().search('').draw();
                                datatable.api().columns(0).search('active-contract').draw();
                            }
                        });
                    }
                },
                // This is necessary to display tooltip on every pages of the datatable (not only the first one)
                drawCallback: function() {
                    tippy('[data-toggle="tooltip"]', {
                        content: (reference) => reference.getAttribute('title'),
                        allowHTML: true,
                    });

                }
            });
        });

        $('[type=search]:not.select2-search__field').each(function () {
            $(this).attr('placeholder', 'Recherche rapide');
        });
    });

    // Tooltips
    $(function () {
        tippy('[data-toggle="tooltip"]', {
            content: (reference) => reference.getAttribute('title'),
            allowHTML: true,
        });
    });

    $(function () {
        MicroModal.init({
            awaitCloseAnimation: true,
            disableScroll: true
        });
    });

    $(function () {
        if($('#certification-modal').length) {
            MicroModal.show('certification-modal', {
                disableScroll: true,
            });
        }
    });

    $(function() {

        $.fn.autoGrow = function(){
            return this.each(function(){
                var colsDefault = this.cols;
                var rowsDefault = this.rows;

                var grow = function() {
                    growByRef(this);
                };

                var growByRef = function(obj) {
                    var linesCount = 0;
                    var lines = obj.value.split('\n');

                    for (var i=lines.length-1; i>=0; --i)
                    {
                        linesCount += Math.floor((lines[i].length / colsDefault) + 1);
                    }

                    if (linesCount > rowsDefault)
                        obj.rows = linesCount;
                    else
                        obj.rows = rowsDefault;
                };

                this.style.overflow = 'hidden';
                this.onkeydown = grow;
                this.onfocus = grow;
                this.onblur = grow;
                this.keypress = grow;
                growByRef(this);
            });
        };
        // $('textarea').autoGrow();

    });

    // Toggle switch
    document.querySelectorAll('.toggle-switch input[type="checkbox"]').forEach(toggleSwitch => {
        toggleSwitch.addEventListener('change', e => {
            e.currentTarget.closest('.toggle-switch').classList.toggle('toggled');
        });
    });

    // Dropdown mobile menu
    var dropdown = document.getElementsByClassName('dropdown-btn');
    var i;

    for (i = 0; i < dropdown.length; i++) {
        dropdown[i].addEventListener('click', function() {
            this.classList.toggle('active');
            var dropdownContent = this.nextElementSibling;
            if (dropdownContent.style.display === 'block') {
                dropdownContent.style.display = 'none';
            } else {
                dropdownContent.style.display = 'block';
            }
        });
    }
}(jQuery));
